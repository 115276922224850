import React, { useState } from 'react';

import { Outlet, Link, useNavigation } from "react-router-dom";
import apiClient from '../apiClient';
import CondomSlideInPanel from './CondomSlideInPanel';

import { loadingStore } from '../Stores/LoadingStore';
import { basketStore } from '../Stores/BasketStore';
import StoreActions from '../Stores/StoreActions';

function LineItem({ model }) {
  const lineItems = [];
  lineItems.push(<div className="basket-block__line" key="mainitem">
    <span>{model.quantity} x {model.name}</span>
    <span className="notranslate">{model.priceNoAddOns}</span>
  </div>);

  for (let i = 0; i < model.addOnsBySkuBoltOnsOnly.length; i++) {
    const boltOn = model.addOnsBySkuBoltOnsOnly[i];
    lineItems.push(<div className="basket-block__line" key={"addon-" + i}>
      <span>{boltOn.quantity} x {boltOn.name}</span>
      <span className="notranslate">{boltOn.totalPrice}</span>
    </div>);
  }
  for (let i = 0; i < model.addOnsBySkuExcludingSwaps.length; i++) {
    const swab = model.addOnsBySkuExcludingSwaps[i];
    lineItems.push(<div className="basket-block__line" key={"addon2-" + i}>
      <span>{swab.name}</span>
      <span className="notranslate">{swab.totalPrice}</span>
    </div>);
  }

  return (lineItems);
}


export default function MiniBasket({ basket, dictionary, availableCondoms, }) {
  const condomOpen = loadingStore.useStore((x)=> x.condomPopupOpen);
  const _openCondomPanel = () => {
    loadingStore.openCondoms()
  };
  
  var _addonChanged = async (add, sku) => {
    loadingStore.startLoading();
    if (add) {
      const response = await apiClient.addAddon(sku)
      const json = await response.json();
      basketStore.updateBasket(json);
      loadingStore.stopLoading();
    } else {

      const response = await apiClient.removeAddon(sku)
      const json = await response.json();
      basketStore.updateBasket(json);
      loadingStore.stopLoading();
    }
  }
  var _setCondomProduct = async (sku) => {
    loadingStore.startLoading();
    loadingStore.closeCondoms();
    const response = await apiClient.setCondomProduct(sku)
    const json = await response.json();
    basketStore.updateBasket(json);
    loadingStore.stopLoading();
  }

  return (

    <div className={"basket-block js-basket-block bg-grey basket-block__popout"}>
      <div className="container">
        {basket.items.length == 0 && (
          <div className="h2">{dictionary["Components.MiniBasket.YourBasketIsEmpty"]}</div>
        )}
        {basket.items.length > 0 && (
          <div className="row basket-block__content">
            <div className="col-12 basket-block__title">
              <div className="h2">{dictionary["Components.MiniBasket.Summary"]}</div>
            </div>
            <div className="col-12 basket-block__lines">
              {basket.items.map((x, i) => {
                return <LineItem model={x} key={i} />
              })}
              <div className="basket-block__line">
                <span>{dictionary["Components.MiniBasket.Delivery"]}</span>
                <span className="notranslate">{basket.deliveryPrice}</span>
              </div>
              {/*Include condoms*/}
              {availableCondoms.length > 0 && (
                <div className="basket-block__line">
                  <div className="form__checkbox w-100 bolton-sidebar-activate state-change-submit" onClick={_openCondomPanel}>
                    <input type="checkbox" placeholder="IncludeCondoms" checked={basket.selectedCondomItem != null} readOnly/>
                    <label htmlFor="IncludeCondoms" style={{ width: "80%" }}>
                      {dictionary["Pages.Checkout.About.CondomBoltOnCostInfo"]}
                    </label>
                    <div className="form__checkbox--tooltip js-form-checkbox-tooltip" style={{ width: "20%" }}>
                      <svg className="px-icon ml-3 c-grey4 px-icon--checkbox-tooltip" title="Information">
                        <use xlinkHref="/assets/icons/symbol/svg/sprite.symbol.svg#information"></use>
                      </svg>
                      <div className="form__checkbox--tooltip-popup copy">
                        {dictionary["Pages.Checkout.About.ComdomBoltOnPopup"]}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/*Collection*/}
              {basket.availableSampleCollections.map((sample, i) => {
                return (
                  <div className="basket-block__line" key={i}>
                    <div className="form__checkbox w-100 bolton-sidebar-activate state-change-submit">
                      <input type="checkbox" placeholder={sample.name} id={"_sample_" + sample.sku} readOnly checked={sample.isSelected} onChange={async () => { await _addonChanged(!sample.isSelected, sample.sku); }} />
                      <label htmlFor={"_sample_" + sample.sku} style={{ width: "80%" }}>
                        {sample.name}
                      </label>
                      <div className="form__checkbox--tooltip js-form-checkbox-tooltip" style={{ width: "20%" }}>
                        <svg className="px-icon ml-3 c-grey4 px-icon--checkbox-tooltip" title="Information">
                          <use xlinkHref="/assets/icons/symbol/svg/sprite.symbol.svg#information"></use>
                        </svg>
                        <div className="form__checkbox--tooltip-popup copy">
                          {sample.tooltipText}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}

              {/*Priority Service*/}
              <div className="basket-block__line">
                <div className="form__checkbox w-100 bolton-sidebar-activate state-change-submit">
                  <input type="checkbox" id="PriorityService" placeholder="Priority Service" readOnly checked={basket.priorityService} onChange={StoreActions.priorityServiceChanged} />
                  <label htmlFor="PriorityService" style={{ width: "80%" }}>
                    {dictionary["Pages.Checkout.About.PriorityServiceCostInfo"]}
                  </label>
                  <div className="form__checkbox--tooltip js-form-checkbox-tooltip" style={{ width: "20%" }}>
                    <svg className="px-icon ml-3 c-grey4 px-icon--checkbox-tooltip" title="Information">
                      <use xlinkHref="/assets/icons/symbol/svg/sprite.symbol.svg#information"></use>
                    </svg>
                    <div className="form__checkbox--tooltip-popup copy">
                      {dictionary["Pages.Checkout.About.PriorityServicePopup"]}
                    </div>
                  </div>
                </div>
              </div>



              <div className="col-12 basket-block__lines">
                <span>{dictionary["Components.MiniBasket.YouWillBeTestedForTheFollowingInfections"]}:</span>
                <ul>
                  {basket.mainItemComponents.map((item, i) => {
                    return (
                      <li className="" key={i}>
                        {item}
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className="col-12 basket-block__lines">
                <span>{dictionary["Components.MiniBasket.YouWillBeRequiredToTakeTheseSamples"]}:</span>
                <ul>
                  {basket.samplesToTake.map((item, i) => {
                    return (
                      <li className="" key={i}>
                        {item}
                      </li>
                    )
                  })}
                </ul>
              </div>

              <div className="row">
                <div className={"col-12 basket-block__total js-basket-popout-open"}>
                  <div className="h2">{dictionary["Components.MiniBasket.OrderTotal"]}</div>
                  <div className="h2 notranslate">{basket.totalPrice}</div>

                  <button className="reset-button d-md-none">
                    <svg className="px-icon ml-2 px-icon--checkout-step" >
                      <use xlinkHref="/assets/icons/symbol/svg/sprite.symbol.svg#angle-right"></use>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>

        )}
      </div>

      {
        basket.popoutOnMobile && (
          <div className="basket-block__overlay js-basket-block-overlay d-md-none"></div>
        )
      }
      <CondomSlideInPanel
        availableCondoms={availableCondoms}
        dictionary={dictionary}
        isOpen={condomOpen}
        setProduct={_setCondomProduct}
        selectedCondomItem={basket.selectedCondomItem}
        closePanel={() => loadingStore.closeCondoms()}
      />
    </div >

  );
}