import AboutYou from './Pages/AboutYou';
import Confirmation from './Pages/Confirmation';
import ContactPreferences from './Pages/ContactPreferences';
import DeliveryDetails from './Pages/DeliveryDetails';
import Payment from './Pages/Payment';
import ThankYou from './Pages/ThankYou';

import Root from './Pages/Root';
import ErrorPage from './Pages/RootError';

import apiClient from './apiClient';
import {
   createBrowserRouter,
   RouterProvider,
} from "react-router-dom";

import './styles.scss';

const router = createBrowserRouter(
   [
      {
         path: "/",
         element: <Root />,
         errorElement: <ErrorPage />,
         children: [
            {
               index: true,
               path: "",
               element: <AboutYou />,
               loader: apiClient.aboutYouGet
            },
            {
               path: "delivery-details",
               element: <DeliveryDetails />,
               loader: apiClient.deliveryDetailsGet 
            },
            {
               path: "contact-preferences",
               element: <ContactPreferences />,
               loader: apiClient.contactPreferencesGet
            },
            {
               path: "confirmation",
               element: <Confirmation />,
               loader: apiClient.confirmationGet

            },
            {
               path: "payment",
               element: <Payment />,
               loader: apiClient.paymentGet

            },
            {
               path: "thank-you",
               element: <ThankYou />,   
               loader: apiClient.thankyouGet

            }
         ]
      }
   ],
   {
      basename: window.checkoutUrl,
   });



const CheckoutApp = () => {
   return <RouterProvider router={router} />;
};

export default CheckoutApp;