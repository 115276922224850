import { loadingStore } from "./Stores/LoadingStore";
const getRequest = async (url) => {
    loadingStore.startLoading();
    return fetch(url, {
        headers: {
            "checkout": window.checkoutId
        }
    }).then((resp)=>{
        loadingStore.stopLoading();
        return resp;
    });
};
const postRequest = async (url, data) => { 
    loadingStore.startLoading();
    return fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
            "Content-Type": "application/json",
            "checkout": window.checkoutId
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    }); 
};


const apiClient = {
    aboutYouGet: async ({ request, params }) => {
        return getRequest("/umbraco/api/AboutYouApi/Get");
    },
    aboutYouSave: async (model) => {
        return postRequest("/umbraco/api/AboutYouApi/Save", model);
    },

    deliveryDetailsGet: async ({ request, params }) => {
        return getRequest("/umbraco/api/DeliveryDetailsApi/Get");
    },
    deliveryDetailsSave: async (model) => {
        return postRequest("/umbraco/api/DeliveryDetailsApi/Save", model);
    },

    contactPreferencesGet: async ({ request, params }) => {
        return getRequest("/umbraco/api/ContactPreferencesApi/Get");
    },
    contactPreferencesSave: async (model) => {
        return postRequest("/umbraco/api/ContactPreferencesApi/Save", model);
    },

    confirmationGet: async ({ request, params }) => {
        return getRequest("/umbraco/api/ConfirmationApi/Get");
    },
    paymentGet: async ({ request, params }) => {
        return getRequest("/umbraco/api/PaymentApi/Get");
    },
    thankyouGet: async ({ request, params }) => {
        return getRequest("/umbraco/api/ThankYouApi/Get");
    }, 
    addPriorityService: async () => {
        return postRequest("/umbraco/api/MiniBasketApi/AddPriorityService");
    },
    removePriorityService: async () => {
        return postRequest("/umbraco/api/MiniBasketApi/RemovePriorityService");
    },
    setCondomProduct: async (sku) => {
        return postRequest("/umbraco/api/MiniBasketApi/SetCondomProduct?sku="+sku);
    }, 
    addAddon: async (sku) => {
        return postRequest("/umbraco/api/MiniBasketApi/AddAddon?sku="+sku);
    },
    removeAddon: async (sku) => {
        return postRequest("/umbraco/api/MiniBasketApi/RemoveAddon?sku="+sku);
    },
};

export default apiClient;