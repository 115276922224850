import { loadingStore } from "./LoadingStore";
import { basketStore } from "./BasketStore";
import apiClient from "../apiClient";

const StoreActions = {
    priorityServiceChanged: async () => {
        loadingStore.startLoading();
        var basket = basketStore.getBasket().basket;
        if (basket.priorityService) {
            const response = await apiClient.removePriorityService()
            const json = await response.json();
            basketStore.updateBasket(json);
            loadingStore.stopLoading();
        } else {
            const response = await apiClient.addPriorityService()
            const json = await response.json();
            basketStore.updateBasket(json);
            loadingStore.stopLoading();
        }
    }
};

export default StoreActions;