import React, { useEffect } from 'react';
 
export default function RenderGroupedValidation({ errors, fieldNames, labelText}) {
    var hasGroupError = false;
    for (let i = 0; i < fieldNames.length; i++) {
        const fieldName = fieldNames[i];
        var error = errors[fieldName];

        var hasError = !!error;
        if(hasError){
            hasGroupError = true;
            break;
        } 
    } 

    if (hasGroupError) {
        return <span className="field-validation-error c-error-red">{labelText} is required</span>;
    }
    else {
        return null;
    }
}