import { createStore } from '@raiz/react-simple-store'

const state = {
  loading: false,
  condomPopupOpen:false
};

const actions = (set, get) => {
  return {
    startLoading: () => set({ loading: true }),
    stopLoading: () => set({ loading: false }),
    openCondoms: () => set({ condomPopupOpen: true }),
    closeCondoms: () => set({ condomPopupOpen: false }),
  };
};

export const loadingStore = createStore({actions, state});