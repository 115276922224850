import React, { useState } from 'react';
import './CondomSlideInPanel.scss';

export default function CondomSlideInPanel({ availableCondoms, dictionary, isOpen, setProduct,selectedCondomItem, closePanel }) {

  var myClass = "condom-sidebar-right" + (isOpen ? " active" : "");
  return (
    <div className="wrapper">
      <nav className={myClass}>

        <div className="dismiss" title="Close" >
          <svg className="px-icon ml-3 c-grey4 px-icon--nav-close" title="nav close" onClick={closePanel}>
            <use xlinkHref="/assets/icons/symbol/svg/sprite.symbol.svg#close"></use>
          </svg>
        </div>

        <div className="sidebar-header">

          <h2>{dictionary["Components.ProductGrid.PickYourCondoms"]}</h2>
        </div>

        <div className="container">
          <div className="row">
            {availableCondoms.map((product, productIndex) => {
              return (<div className="col-12 col-md-6" key={product.Sku} >
                <div className="card">
                  <div className="h2 w-100 mb-1 mt-1" style={{ textAlign: "center" }}>
                    {product.Title}
                  </div>
                  <div className="w-100" style={{ textAlign: "center" }}><img src={product.ImageUrl} loading="lazy" decoding="async" /></div>
                  <div className="product-grid__card-description copy m-1">{product.Text}</div>
                  <p className="card-price notranslate m-0" style={{ textAlign: "center" }}>
                    <span>{product.Price}</span> + {dictionary["Components.ProductGrid.FreeDeliveryText"]}
                  </p>
                  {!selectedCondomItem && (
                    <button className="button button--nav" onClick={async () => {await setProduct(product.Sku);}}>Add to basket</button>
                  )}

                  {selectedCondomItem && product.Sku != selectedCondomItem && (
                    <button className="button button--nav" onClick={async () => {await setProduct(product.Sku);}}>Select</button>
                  )}
                   {product.Sku == selectedCondomItem && (
                    <button className="button button--white button--nav mr-md-2 nav__link" onClick={async () => {await setProduct("");}}>Remove</button>
                  )}
                </div>
              </div>);
            })}
          </div>
        </div>

      </nav>
    </div>
  );
}