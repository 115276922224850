import React, { useEffect } from 'react';
 
export default function RenderValidation({ errors, fieldName, labelText }) {
    var error = errors[fieldName];
    var hasError = !!error;
    if (hasError) {
        if(error.type == "pattern"){
            return <span className="field-validation-error c-error-red">A valid {labelText} is required.</span>;
        }else{
            return <span className="field-validation-error c-error-red">{labelText} is required.</span>;
        } 
    }
    else {
        return null;
    }
}