import React, { useState, useEffect } from 'react';
import { useLoaderData, Link, useSubmit, useNavigate } from 'react-router-dom';
import PageWrapper from '../PageWrapper';
import renderSigninLink from './renderSigninLink';
import { useForm } from "react-hook-form"
import TextBox from '../Common/TextBox';
import Dropdown from '../Common/Dropdown';
import apiClient from '../../apiClient';
import RenderGroupedValidation from '../Common/RenderGroupedValidation';
import RenderValidation from '../Common/RenderValidation';
import moment from 'moment';
import { loadingStore } from '../../Stores/LoadingStore';
import { basketStore } from '../../Stores/BasketStore';
import StoreActions from '../../Stores/StoreActions';
import { updateAnalyticsStepData }  from '../../helpers/tagManagerHelper';

export default function AboutYou() {
    var data = useLoaderData();
    const navigate = useNavigate();
    const basket = basketStore.useStore((s) => s.basket);
    const toViewModel = (model) => {
        if (model) {
            model.mycoplasma = model.mycoplasma ? "Yes" : "No";
        }
        return model;
    };
    const fromViewModel = (model, currentSwabOptions) => {
        model.mycoplasma = model.mycoplasma == "Yes";
        if (model.swab == null && currentSwabOptions.length == 1) {
            model.swab = currentSwabOptions[0].Value;
        }
        return model;
    };

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({ defaultValues: toViewModel(data.Form) })

    const onSubmit = (data, currentSwabOptions) => {
        loadingStore.startLoading();
        apiClient.aboutYouSave(fromViewModel(data, currentSwabOptions)).then(() => {
            navigate("/delivery-details");
            loadingStore.stopLoading();
        });
    }
    const validateDateOfBirth = (value, formValues) => {
        var day = formValues.dateOfBirthDay;
        var month = formValues.dateOfBirthMonth;
        var year = formValues.dateOfBirthYear;
        var dob = moment({ year: year, month: month - 1, day: day });
        var now = moment();

        var isValid = dob.isValid()
        if (!isValid) {
            console.log("dob is not valid");

            return false;
        }
        var age = now.diff(dob, 'years')
        if (age < 18) {
            console.log("age is < 18 ", age);

            return false;
        }
        return true;
    }
    var currentGender = Number(watch("gender"));
    let currentSwabOptions = data.SwabOptions.map((x, i) => {
        if (x.FilterInformation.includes(currentGender)) {
            return x;
        }
        return null;
    }).filter(e => e != null);

    useEffect(() => {
        updateAnalyticsStepData(1, data.Basket) 
    }, []);
    
    return <PageWrapper currentStepId={0} >
        <form onSubmit={handleSubmit((data) => onSubmit(data, currentSwabOptions))}>
            {!data.IsSignedIn && renderSigninLink(data)}

            <div className="row">
                <div className="col-12">
                    <h2 className="h1">{data.Dictionary["Pages.Checkout.About.PageTitle"]}</h2>
                </div>


                <div className="col-12 col-lg-6 mb-4 mb-lg-0">
                    <TextBox
                        register={register}
                        errors={errors}
                        labelText="First name"
                        fieldName="firstName"
                        required
                    />
                </div>
                <div className="col-12 col-lg-6">
                    <TextBox
                        register={register}
                        errors={errors}
                        labelText="Last name"
                        fieldName="lastName"
                        required
                    />
                </div>
                <div className="col-12 mb-3 mb-lg-4">
                    <div className="form-explanatory-text">
                        This will be displayed on address labels and test results.
                    </div>
                </div>

                <div className="col-12">
                    <div className="mb-4">
                        <div className="form-date">
                            <label className="form-field-label"  >Date of birth</label>
                            <input className="form-field" type="number" placeholder="DD" {...register("dateOfBirthDay", { required: true, validate: validateDateOfBirth })} />
                            <input className="form-field" type="number" placeholder="MM" {...register("dateOfBirthMonth", { required: true, validate: validateDateOfBirth })} />
                            <input className="form-field" type="number" placeholder="YYYY" {...register("dateOfBirthYear", { required: true, validate: validateDateOfBirth })} />
                        </div>
                        <div>
                            <RenderGroupedValidation errors={errors} fieldNames={["dateOfBirthDay", "dateOfBirthMonth", "dateOfBirthYear"]} labelText={"A valid date of birth"} />
                        </div>
                        <div className="form-explanatory-text">We use your date of birth to help us identify you and your results.</div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="mb-4">
                        <Dropdown
                            register={register}
                            required
                            errors={errors}
                            options={data.GenderOptions}
                            explanatoryText={data.Dictionary["Pages.Checkout.About.Gender.ExplanationText"]}
                            fieldName="gender"
                            labelText="Gender"
                        />
                    </div>
                </div>

                {data.HasOptionalSwabs && currentSwabOptions.length > 1 && (
                    <div className="col-12">
                        <div className="mb-4">
                            <Dropdown
                                register={register}
                                required
                                errors={errors}
                                options={data.SwabOptions}
                                explanatoryText={data.Dictionary["Pages.Checkout.About.Swab.ExplanationText"]}
                                fieldName="swab"
                                labelText="Genital Sample Preference"
                                optionFilter={(x) => {
                                    var currentGender = Number(watch("gender"));
                                    return x.FilterInformation.includes(currentGender);
                                }}
                            />
                        </div>
                    </div>
                )}
                {data.OrderIncludesMGen && (
                    <div className="col-12 mb-3">
                        <p className="c-black checkout__checkoutasguest emphasised">
                            <strong>{data.Dictionary["Pages.Checkout.About.MGenHeader"]}</strong><br />
                            {data.Dictionary["Pages.Checkout.About.MGen.ExplanationText"]}
                        </p>
                        <p>

                        </p>
                        <label className="form-field-label" >I believe I have had sex with someone who has recently tested positive for MGen and need to be tested</label>
                        <label>
                            <input {...register("mycoplasma", { required: true })} type="radio" value="Yes" />
                            Yes
                        </label>
                        <label >
                            <input {...register("mycoplasma", { required: true })} type="radio" value="No" />
                            No
                        </label>
                        <RenderValidation errors={errors} fieldName={"mycoplasma"} labelText={"Mycoplasma"} />
                    </div>
                )}


                <div className="col-12 mb-3">
                    <h2 className="h1 mb-0">{data.Dictionary["Pages.Checkout.About.NeedResultsQuickly"]}</h2>
                    <h3 className="h2">{data.Dictionary["Pages.Checkout.About.PriorityServiceCostInfo"]}</h3>

                    <div className="mb-4">
                        <div className="form__checkbox">
                            <input type="checkbox" id="PriorityServiceay" placeholder="Priority Service" readOnly checked={basket?.priorityService ? true : false} onChange={StoreActions.priorityServiceChanged} />

                            <label htmlFor='PriorityServiceay'>
                                Yes please
                            </label>
                            <div className="form__checkbox--tooltip js-form-checkbox-tooltip">
                                <svg className="px-icon c-grey4 px-icon--checkbox-tooltip"><use xlinkHref="/assets/icons/symbol/svg/sprite.symbol.svg#information"></use></svg>
                                <div className="form__checkbox--tooltip-popup copy">
                                    {data.Dictionary["Pages.Checkout.About.PriorityServicePopup"]}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        dangerouslySetInnerHTML={{ __html: data.PriorityServiceDescription }}
                    />
                </div>

                {data.AvailableCondoms && data.AvailableCondoms.length > 0 && (
                    <div className="col-12 mb-3">
                        <h2 className="h1 mb-0">{data.Dictionary["Pages.Checkout.About.CondomBoltOnTitle"]}</h2>
                        <h3 className="h2">{data.Dictionary["Pages.Checkout.About.CondomBoltOnSubtitle"]}</h3>

                        <div className="mb-4">
                            <div className="form__checkbox bolton-sidebar-activate">
                                <input type="checkbox" id="condoms-ay" placeholder="Condoms" readOnly checked={basket?.selectedCondomItem != null} onChange={loadingStore.openCondoms} />

                                <label htmlFor='condoms-ay'>
                                    Yes please
                                </label>
                                <div className="form__checkbox--tooltip js-form-checkbox-tooltip">
                                    <svg className="px-icon c-grey4 px-icon--checkbox-tooltip"><use xlinkHref="/assets/icons/symbol/svg/sprite.symbol.svg#information"></use></svg>
                                    <div className="form__checkbox--tooltip-popup copy">
                                        {data.Dictionary["Pages.Checkout.About.CondomBoltOnPopup"]}</div>
                                </div>
                            </div>
                        </div>
                        <div
                            dangerouslySetInnerHTML={{ __html: data.CondomsDescription }}
                        />
                    </div>
                )}

                <div className="col-12 col-md-7">
                    <button type="submit" className="reset-button button w-100">
                        {data.Dictionary["Components.Forms.Continue"]}
                    </button>
                </div>
            </div>
            
        </form>
    </PageWrapper>;
}