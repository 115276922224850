import React, { useState, useEffect } from 'react';
import { useLoaderData, Link, useSubmit, useNavigate } from 'react-router-dom';
import PageWrapper from '../PageWrapper';
import { useForm } from "react-hook-form"
import TextBox from '../Common/TextBox';
import apiClient from '../../apiClient';
import { loadingStore } from '../../Stores/LoadingStore';
import { updateAnalyticsStepData }  from '../../helpers/tagManagerHelper';

export default function DeliveryDetails() {
    var data = useLoaderData();
 

    const navigate = useNavigate();

 
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({ defaultValues: data.Form })

    const onSubmit = (data) => {
        loadingStore.startLoading();
        apiClient.deliveryDetailsSave(data).then(() => {
            navigate("/contact-preferences");
            loadingStore.stopLoading();
        });
    }

    useEffect(() => {
        updateAnalyticsStepData(2, data.Basket) 
    }, []);

    return <PageWrapper currentStepId={1}>
        <form onSubmit={handleSubmit(onSubmit)}>

            <div className="row">
                <div className="col-12">
                    <h2 className="h1">{data.Dictionary["Pages.Checkout.DeliveryDetails.PageTitle"]}</h2>
                </div>
                <div className="col-12 col-lg-10">
                    <div className="mb-4">
                        <div className="form-address-locator mb-4">
                            {/* Regex from https://stackoverflow.com/questions/164979/regex-for-matching-uk-postcodes#comment63473399_164994 */}
                            <TextBox
                                register={register}
                                errors={errors}
                                labelText="Postcode"
                                fieldName="postCode"
                                required
                                
                                pattern={/^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/}
                             />
                            <div className="form-explanatory-text invisible">{data.Dictionary["Pages.Checkout.DeliveryDetails.PostCode.ExplanationText"]}</div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-10">
                    <TextBox
                        register={register}
                        errors={errors}
                        labelText="Address Line 1"
                        fieldName="addressLine1"
                        required
                    />
                </div>
                <div className="col-12 col-lg-10">
                    <TextBox
                        register={register}
                        errors={errors}
                        labelText="Address Line 2"
                        fieldName="addressLine2"
                    />
                </div>
                <div className="col-12 col-lg-10">
                    <TextBox
                        register={register}
                        errors={errors}
                        labelText="City"
                        fieldName="city"
                        required
                    />
                </div>
                <div className="col-12 col-lg-10">
                    <TextBox
                        register={register}
                        errors={errors}
                        labelText="County"
                        fieldName="county"
                        required
                    />
                </div>
                <div className="col-6 col-lg-5 my-3">
                    <Link to="/" className="button button--white" >Back</Link>

                </div>
                <div className="col-6 col-lg-5 my-3">
                    <button type="submit" className="reset-button button w-100">
                        {data.Dictionary["Components.Forms.Continue"]}
                    </button>
                </div>

            </div>

        </form>
    </PageWrapper>;
}