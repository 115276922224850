import React, { useState, useEffect } from 'react';
import { useLoaderData, Link, useSubmit, useNavigate } from 'react-router-dom';
import PageWrapper from '../PageWrapper';
import { useForm } from "react-hook-form"
import TextBox from '../Common/TextBox';
import apiClient from '../../apiClient';
import { loadingStore } from '../../Stores/LoadingStore';
import Dropdown from '../Common/Dropdown';
import { updateAnalyticsStepData }  from '../../helpers/tagManagerHelper';

export default function ContactPreferences() {
    var data = useLoaderData();

    const navigate = useNavigate();


    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({ defaultValues: data.Form })

    const onSubmit = (data) => {
        loadingStore.startLoading();
        apiClient.contactPreferencesSave(data).then(() => {
            navigate("/confirmation");
            loadingStore.stopLoading();
        });
    }

    useEffect(() => {
        updateAnalyticsStepData(3, data.Basket) 
    }, []);

    return <PageWrapper currentStepId={2} >
        <form onSubmit={handleSubmit(onSubmit)}>

            <div className="row">
                <div className="col-12">
                    <h2 className="h1">Your contact preferences</h2>
                </div>
                <div className="col-12 col-lg-10 mb-4">
                    <TextBox
                        register={register}
                        errors={errors}
                        labelText="Email address"
                        fieldName="email"
                        required
                        explanatoryText="We will keep you up to date with the results of your test kit and results"
                    />
                </div>

                <div className="col-12 col-lg-10 mb-4">
                    <TextBox
                        register={register}
                        errors={errors}
                        labelText="Mobile number"
                        fieldName="mobile"
                        required
                        pattern={/(\\+44|0)[0-9]{10}/}
                        explanatoryText="We will send you SMS messages to keep you up to date with the status of your test kit and results"
                    />
                </div>

                <div className="col-12 mb-4">
                    <Dropdown
                        register={register}
                        required
                        errors={errors}
                        options={[
                            { Name: "SMS Only", Value: "sms" },
                            { Name: "Email Only", Value: "email" },
                            { Name: "SMS and Email", Value: "any" },
                        ]}
                        explanatoryText="We will send you a one-time reminder in 3-6 months to test again"
                        fieldName="contactMethodPreferences"
                        labelText="Notification preference"
                        optionFilter={(x) => {
                            return x
                        }}
                    />
                </div>
                <div className="col-12 col-lg-10">
                    <div className="form__checkbox">   
                        <input type="checkbox" placeholder="marketingOptIn" id="marketingOptIn" {...register("marketingOptIn")} />
 
                        <label htmlFor="marketingOptIn">
                            Let’s stay in touch. I agree to receive personalised marketing emails and offers from test.me. Our <a href="/privacy-policy">Privacy Policy</a> describes how we handle and protect your information. You may be contacted by a clinical professional to discuss you order/results.
                        </label>
                    </div>
                </div>
 
                <div className="col-6 col-lg-5 my-3">
                    <Link to="/delivery-details" className="button button--white" >Back</Link>
                </div>
                <div className="col-6 col-lg-5 my-3">
                    <button type="submit" className="reset-button button w-100">
                        {data.Dictionary["Components.Forms.Continue"]}
                    </button>
                </div>

            </div>

        </form>
    </PageWrapper>;
}