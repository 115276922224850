import React, { useState, useEffect } from 'react';
import { useLoaderData, Link, useSubmit, useNavigate } from 'react-router-dom';
import PageWrapper from '../PageWrapper';
import { useForm } from "react-hook-form"
import TextBox from '../Common/TextBox';
import apiClient from '../../apiClient';
import Dropdown from '../Common/Dropdown';
import { loadingStore } from '../../Stores/LoadingStore';
import { updateAnalyticsStepData }  from '../../helpers/tagManagerHelper';

export default function Confirmation() {
    var data = useLoaderData();

    const navigate = useNavigate();


    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({ defaultValues: data.Form })

    const onSubmit = (data) => {
        loadingStore.startLoading();
        navigate("/payment");
        loadingStore.stopLoading();
    }

    useEffect(() => {
        updateAnalyticsStepData(4, data.Basket) 
    }, []);

    return <PageWrapper currentStepId={3}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-12">
                    <h2 className="h1">{data.Dictionary["Pages.Checkout.ConfirmAndPay.PageTitle"]}</h2>
                </div>
                <div className="col-12 col-lg-10">
                    <p>
                        <strong>{data.Dictionary["Pages.Checkout.ConfirmAndPay.YourDeliveryDetails"]}</strong><br />
                        <span className="copy emphasised">{data.Dictionary["Pages.Checkout.ConfirmAndPay.AddressLabelInfo"]}</span>
                    </p>

                    <p dangerouslySetInnerHTML={{ __html: data.DeliveryAddress }}>
                        
                    </p>
                    {data.IsSignedIn && data.AccountDetailsUrl && (
                        <p className="emphasised">
                            <a href={data.AccountDetailsUrl} className="hyperlink--underline hyperlink--black">
                                {data.Dictionary["Pages.Checkout.ConfirmAndPay.EditDeliveryDetails"]}
                            </a>
                        </p>
                    )}
                </div>
                <div className="col-12 col-lg-10">
                    <p>
                        <strong>{data.Dictionary["Pages.Checkout.ConfirmAndPay.YourContactPreferences"]}</strong><br />
                        <span className="copy emphasised">{data.Dictionary["Pages.Checkout.ConfirmAndPay.WeWillKeepYouNotifiedUsing"]}</span>
                    </p>

                    <ul>
                        <li>{data.Dictionary["Pages.Checkout.ConfirmAndPay.EmailTo"]}: {data.CustomerEmail}</li>
                        {data.CustomerMobile && (
                            <li>{data.Dictionary["Pages.Checkout.ConfirmAndPay.SmsTo"]}: {data.CustomerMobile}</li>
                        )}
                        {data.NotificationPreference == "any" && (
                            <li>{data.Dictionary["Pages.Checkout.ConfirmAndPay.EmailSmsReminder"]}</li>
                        )}
                        {data.NotificationPreference == "sms" && (
                            <li>{data.Dictionary["Pages.Checkout.ConfirmAndPay.SmsReminder"]}</li>
                        )}
                        {data.NotificationPreference == "email" && (
                            <li>{data.Dictionary["Pages.Checkout.ConfirmAndPay.EmailReminder"]}</li>
                        )}
                        {data.IncludesCondoms && (
                            <li>{data.Dictionary["Pages.Checkout.ConfirmAndPay.CondomBoltOnShipmentMessage"]}</li>
                        )}
                    </ul>
              
                    {data.IsSignedIn && data.ContactPreferencesUrl && (
                        <p className="emphasised">
                            <a href={data.ContactPreferencesUrl} className="hyperlink--underline hyperlink--black">
                                {data.Dictionary["Pages.Checkout.ConfirmAndPay.EditContactPreferences"]}
                            </a>
                        </p>
                    )}

                </div>
                <div className="col-6 col-lg-5 my-3">
                    <Link to="/contact-preferences" className="button button--white" >Back</Link>
                </div>
                <div className="col-6 col-lg-5 my-3">
                    <button type="submit" className="reset-button button w-100">Confirm & Make Payment</button>
                </div>
            </div>



        </form>
    </PageWrapper>;
}