let priorityTesting = null;

const addTagManagerData = (action, data) => {
  GoogleTagManagerHelper.setEcommerceDataLayer(action, data);
}

const stripNonNumericAndConvert = (str) => {
  // Remove any non-numeric characters from the start of the string
  let cleanedStr = str.replace(/^[^\d.-]+/, '');
  
  // Convert the cleaned string to a decimal (float)
  let decimalValue = parseFloat(cleanedStr);
  
  // Return the decimal value
  return decimalValue;
}

const getProducts = (basket) => {
  if (!basket) {
    // 100% discount code
    return;
  }
  
  var products = [{
    'name': basket.items[0].name,
    'id': basket.items[0].sku,
    'price': stripNonNumericAndConvert(basket.items[0].priceNoAddOns),
    'brand': GoogleTagManagerHelper.BRAND_PREVENTX,
    'category': GoogleTagManagerHelper.CATEGORY_STI_KITS,
    'list': GoogleTagManagerHelper.LIST_DEFAULT,
    'quantity': 1
    }
  ];

  if (basket.addOnChargableProducts) {
    for (var i = 0; i < basket.addOnChargableProducts.length; i++) {
        products.push(
            {
                'name': basket.addOnChargableProducts[i].Name,
                'id': basket.addOnChargableProducts[i].Sku,
                'price': stripNonNumericAndConvert(basket.addOnChargableProducts[i].TotalPrice),
                'brand': GoogleTagManagerHelper.BRAND_PREVENTX,
                'category': GoogleTagManagerHelper.CATEGORY_STI_KITS,
                'list': GoogleTagManagerHelper.LIST_DEFAULT,
                'quantity': 1
            });
    }
  }

  return products;
}

export const updateAnalyticsStepData = (step, basket) => {
  addTagManagerData('eec_checkout',
    {
        'checkout': {
            'actionField': {
                'step': step,
            }
        }
    });
}

export const updateAnalyticsPurchaseData = (orderNumber, basket) => {
  if (!basket || basket.items == null) {
    // 100% discount code
    return;
  }

  addTagManagerData('eec_purchase',
    {
      'currencyCode': 'GBP',
      'purchase': {
          'actionField': {
              'id': orderNumber,
              'affiliation': 'Online Store', 
              'revenue': stripNonNumericAndConvert(basket.totalPrice),
              'tax': basket.tax,
              'shipping': basket.deliveryPrice == "Free" ? 0 : stripNonNumericAndConvert(basket.deliveryPrice),
              'coupon': basket.promoCode
          },
          'products': getProducts(basket)
      }
  });
}

export const updateAnalyticsBasketData = (basket) => {
  const GoogleTagManagerHelper = window.GoogleTagManagerHelper;

  const analyticsData = {
      'currencyCode': 'GBP',
      'checkout': {
          'products': getProducts(basket)
      }
  };

  addTagManagerData('eec_checkout', analyticsData);

  if (priorityTesting != basket.priorityTesting) {
    priorityTesting = basket.priorityTesting;
    addTagManagerData('eec_checkout',
      {
          'checkout': {
              'actionField': {
                  'step': 2,
                  'option': 'Priority Testing Service'
              }
          }
      });
  }
}