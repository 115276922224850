import React, { useEffect } from 'react';
import './styles.scss'
import RenderValidation from './RenderValidation';
export default function TextBox({ explanatoryText, labelText, fieldName, register, required, errors, pattern}) {
     let fields = <>

        {labelText && (
            <label className="form-field-label" htmlFor={fieldName}>{labelText}</label>
        )}

        <input className="form-field" id={fieldName} name={fieldName} type="text" {...register(fieldName, { required: required, pattern: pattern })} />

        {explanatoryText && (
            <div className="form-explanatory-text">
                {explanatoryText}
            </div>
        )}
        <RenderValidation  errors={errors} fieldName={fieldName} labelText={labelText}/>
        
    </>;
    return fields;
}