import { createStore } from '@raiz/react-simple-store'
import { shallowEqual } from '@raiz/react-simple-store/utils'
import { updateAnalyticsBasketData } from '../helpers/tagManagerHelper';

const state = {
    basket: null,
};

const actions = (set, get) => {
    return {
        updateBasket: function (basket) {
            console.log("update basket", basket);
            updateAnalyticsBasketData(basket);
            set({ basket: basket })
        },
        setInitialBasket: function (basket) {
            if (get().basket == undefined) {
                console.log("init basket", basket);
                updateAnalyticsBasketData(basket);
                set({ basket: basket })
            }
        },
        getBasket:function (){
            return get();
        }
    };
};

export const basketStore = createStore({ actions, state });
basketStore.useStoreShallow = selector => basketStore.useStore(selector, shallowEqual)
