export default function renderSigninLink(data) {
    return <><div className="row checkout__accountwithus--wrap">
        <div className="col-12">
            <p className="checkout__accountwithus my-md-0 pb-md-4">
                <strong>
                    {data.Dictionary["Pages.Checkout.About.HaveAnAccount"]}
                    <span className="ml-2"><a href="/sign-in/?returnUrl=%2Fcheckout%2F" className="hyperlink--underline">Sign in</a></span>
                </strong>
            </p>
        </div>
    </div>

        <div className="row checkout__checkoutasguest--wrap">
            <div className="col-12">
                <p className="c-black checkout__checkoutasguest emphasised">
                    <strong>{data.Dictionary["Pages.Checkout.About.CheckoutAsGuest"]}</strong><br />
                    {data.Dictionary["Pages.Checkout.About.CheckoutAsGuestInfo"]}
                </p>
            </div>
        </div></>
}