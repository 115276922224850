import React, { useEffect } from 'react';
import { useLoaderData, useLocation } from 'react-router-dom';
import PageWrapper from '../PageWrapper';
import { updateAnalyticsPurchaseData }  from '../../helpers/tagManagerHelper';

export default function Payment() {
    var data = useLoaderData();
    const location = useLocation();
    const order = location.state?.order;
    
    const GoogleTagManagerHelper = window.GoogleTagManagerHelper;

    useEffect(() => {
        updateAnalyticsPurchaseData(data.OrderNumber, data.Basket);
    }, []);

    return <PageWrapper currentStepId={5} isSubmitting={false} hideSidebar={true}>

        <div className="row">
            <div className="col-12">
                <h2 className="h1 mt-md-0">{data.Title}</h2>
            </div>

            <div className="col-12 col-lg-10">
                <h3 className="mt-0">Order Number: <span>{order ? order.orderId : data.OrderNumber}</span></h3>
            </div>

            <div className="col-12 col-lg-10">
                <div
                    dangerouslySetInnerHTML={{ __html: data.ThankYouText }}
                />
            </div>

            <div className="col-12 col-lg-10 checkout__referral_block">
                <div className="checkout__referral_block">
                    {
                        data.AllocatedReferralCode && (
                            <>
                                <h2>{data.ReferralSectionTitle}</h2>
                                <p>{data.ReferralCodeInformation}</p>
                                <h2>{data.AllocatedReferralCode}</h2>
                                <a href={"mailto:?subject=" + data.ReferralEmailSubject + "&body=" + data.ReferralEmailBody}>
                                    <svg className="px-icon c-grey4 px-icon--checkbox-tooltip" title="Email">
                                        <use xlinkHref="/assets/icons/symbol/svg/sprite.symbol.svg#mail"></use>
                                    </svg>
                                </a>
                            </>
                        )
                    }
                </div>
            </div>
            <div className="col-12 col-lg-10">
            <div
                    dangerouslySetInnerHTML={{ __html: data.WhatHappensNextText }}
                />
             </div>
            {data.IncludeCondoms && (
                <div className="col-12 col-lg-10 mb-4"> 
                {data.Dictionary["Pages.Checkout.ThankYou.CondomBoltOnShipmentMessage"]}
                 </div>
            )}
            {data.HelpAndSupportPageUrl && (
                <div className="col-12 col-lg-10">
                    <a href={data.HelpAndSupportPageUrl} className="button button--inline">Help collecting samples</a>
                </div>
            )}
        </div>

    </PageWrapper>;
}