import classNames from 'classnames';
import React, { useState } from 'react';

import { Link, useNavigation, useLoaderData } from "react-router-dom";
import './PageWrapper.scss';
import MiniBasket from './MiniBasket';
import CondomSlideInPanel from './CondomSlideInPanel';

import { loadingStore } from '../Stores/LoadingStore';
import { basketStore } from '../Stores/BasketStore';
const steps = [
    {
        url: "/",
        name: "About You"
    },
    {
        url: "/delivery-details",
        name: "Delivery Details"
    },
    {
        url: "/contact-preferences",
        name: "Contact Preferences"
    },
    {
        url: "/confirmation",
        name: "Confirmation"
    },
    {
        url: "/payment",
        name: "Payment"
    },
    {
        url: "/thank-you",
        name: "Thank You"
    }
];

function RenderCheckoutNavigation(currentStepId) {
    return steps.map((x, i) => {
        let isActive = i == currentStepId;
        let visited = currentStepId > i
        let classes = classNames(
            "checkout-steps__step",
            "col-12",
            "col-md",
            "py-2",
            "py-md-3",
            "order-" + i,
            "order-md-" + i,
            {
                "visited": visited,
                "active": isActive,
                "js-active-step": isActive
            });


        return (<div className={classes} key={i}>
            {visited && (
                <Link to={x.url}>{x.name}</Link>
            )}
            {!visited && (
                <>{x.name}</>
            )}
        </div>);
    });
}
export default function PageWrapper({ children, currentStepId, hideSidebar }) {
    const data = useLoaderData();
    basketStore.setInitialBasket(data.Basket);

    const isLoading = loadingStore.useStore((s) => s.loading);
    const basket = basketStore.useStore((s) => s.basket);

    return (
        <div className={(isLoading ? "step-loading" : "loaded")}>
            {isLoading &&
                <div className='loader-wrapper'>
                    <span className="loader"></span>
                </div>
            }

            <section className="section--larger checkout-form">
                <div className="responsive-container">
                    <div className="row">
                        <div className="col-12 responsive-container__column position-relative">
                            <div className="row py-1 py-md-0 checkout-steps js-checkout-steps">
                                {RenderCheckoutNavigation(currentStepId)}
                            </div>
                        </div>
                    </div>
                </div>
                {!hideSidebar &&
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-8 mb-2 mb-md-0 js-active-step-content">
                                {children}
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                <MiniBasket basket={basket} dictionary={data.Dictionary} availableCondoms={data.AvailableCondoms} />
                            </div>
                        </div>
                    </div>
                }
                {hideSidebar &&
                    <div className="container">
                        <div className="row">
                            <div className="col-12  mb-2 mb-md-0 js-active-step-content">
                                {children}
                            </div>
                        </div>
                    </div>
                }
            </section>
        </div>
    );
}