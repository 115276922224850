import React, { useState, useEffect } from 'react';
import { useLoaderData, Link, useSubmit, useNavigate } from 'react-router-dom';
import PageWrapper from '../PageWrapper';
import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js';
import { loadingStore } from '../../Stores/LoadingStore';
import { updateAnalyticsStepData }  from '../../helpers/tagManagerHelper';

const stripePromise = loadStripe(window.stripeApiKey);

const CheckoutForm = ({ data }) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [isCardComplete, setIsCardComplete] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);
        if (!isCardComplete) {
            setErrorMessage('Please complete the card details.');
            loadingStore.stopLoading();
            return;
        }
        
        loadingStore.startLoading();
        const done = await stripe.confirmCardPayment(data.ClientSecret,
            {
                return_url: data.ThankYouPage,
                payment_method: {
                    card: cardElement,
                },
            });

        if (done.error) {
            // Show error to your customer (e.g., insufficient funds)
            console.log(`confirmCardPayment ErrorMessage: ${result.error.message}, Result: ${JSON.stringify(result)}`);
        } else {
            navigate("/thank-you");
        }
        loadingStore.stopLoading();
    };

    useEffect(() => {
        updateAnalyticsStepData(5, data.Basket) 
    }, []);

    const handleCardChange = (event) => {
        setIsCardComplete(event.complete);
        if (event.error) {
            setErrorMessage(event.error.message);
        } else {
            setErrorMessage(null); // Clear error message if no error
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="col-12 my-3 pr-1">
                <div className="row">
                    <div className="col-12 col-lg-10">
                        <div className="stripe-wrapper">
                            <CardElement
                                id="card-element"
                                options={{
                                    style: {
                                        base: {
                                            color: "#32325d",
                                            fontFamily: 'Arial, sans-serif',
                                            fontSmoothing: "antialiased",
                                            fontSize: "16px",
                                            "::placeholder": {
                                                color: "#32325d"
                                            }
                                        },
                                        invalid: {
                                            fontFamily: 'Arial, sans-serif',
                                            color: "#fa755a",
                                            iconColor: "#fa755a"
                                        }
                                    },
                                    hidePostalCode: true,
                                    value: {
                                        postalCode: data.Postcode
                                    }
                                }}
                                onChange={handleCardChange}
                            />
                        </div>
                        {errorMessage ? 
                            <span class="field-validation-error c-error-red">{errorMessage}</span>
                            : ''}
                    </div>
                </div>
                <div className="row"><div className="col-6 col-lg-5 my-3 pr-1">
                    <a href="/checkout/confirmation" className="button button--white">
                        Back
                    </a>
                </div>
                    <div className="col-6 col-lg-5 my-3 pr-1">
                        <button id="submit" className="button button--stripepayment w-100" type='submit' disabled={!stripe}>
                            <span id="button-text">Pay now</span>
                        </button>
                    </div>
                </div>
            </div>

        </form>
    )
};



export default function Payment() {
    var data = useLoaderData();
    const navigate = useNavigate();


    useEffect(() => {
        if (data.DiscountOrder) {
            const orderData = {
                orderId: data.DiscountCodeCheckoutOrderId,
                orderStatus: "DiscountOrder"
            };
            navigate("/thank-you", { state: { order: orderData } });
        }
    }, [data.DiscountOrder, data.DiscountCodeCheckoutOrderId, navigate]);

    const options = {
        // passing the client secret obtained from the server
        clientSecret: data.ClientSecret,
    };
    console.log("options", options);

    return <PageWrapper currentStepId={4}>
        <div className="col-12">
            <h2 className="h1 mt-md-0">Payment</h2>
        </div>
        {!data.DiscountOrder && (
            <Elements stripe={stripePromise} options={options}>
                <CheckoutForm data={data} />
            </Elements>
        )}
\

    </PageWrapper>;
}