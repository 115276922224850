import React, { useEffect } from 'react';
import './styles.scss'

export default function Dropdown({ explanatoryText, labelText, fieldName, register, required, errors, options, optionFilter }) {
    var error = errors[fieldName];
    var hasError = !!error;

    let optionElements = options.map((x, i) => {
        if (!optionFilter || optionFilter(x)) {
            return <option value={x.Value} key={i}>{x.Name}</option>
        }
        return null;
    }).filter(e => e != null);

    var hasMultipleOptions = optionElements.length  > 1;

    let fields = <>
        {labelText && (
            <label className="form-field-label" htmlFor={fieldName}>{labelText}</label>
        )}
        <div className="form-field form-field--selectwrapper">
     
            <select className="form-field" {...register(fieldName, { required: hasMultipleOptions })}>
                {hasMultipleOptions && required && (
                    <option value="">Please select</option>
                )}
                {optionElements}
            </select>

            <div className="form-field__dropdown-arrow-wrap">
                <span className="form-field__dropdown-arrow">
                    <svg className="px-icon px-icon--dropdown-arrow"><use xlinkHref="/assets/icons/symbol/svg/sprite.symbol.svg#line-arrow"></use></svg>
                </span>
            </div>
        </div>
 
        {explanatoryText && (
            <div className="form-explanatory-text">
                {explanatoryText}            </div>
        )}

        {hasError && (
            <span className="field-validation-error c-error-red">{labelText} is required</span>
        )}
    </>;
    return fields;
}