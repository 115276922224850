import React from 'react';
import { createRoot } from 'react-dom/client';

import CheckoutApp from './Apps/Checkout/index.jsx';

const mountNodes = Array.from(document.querySelectorAll("[data-react-component]"));


if (mountNodes && mountNodes !== null && mountNodes.length > 0) {
    mountNodes.forEach((mountNode /*, index */) => {
        if (mountNode != null) {
            const component = mountNode.dataset?.reactComponent;
            const root = createRoot(mountNode);

            var node = null;

            if (component == "checkout") {
                const baseUrl = mountNode.dataset.baseUrl;
                const checkoutId= mountNode.dataset.checkoutId;

                node = <CheckoutApp baseUrl={baseUrl} checkoutId={checkoutId}/>
            }
            root.render(node);
        }
    });
}