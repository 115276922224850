import React, { useState } from 'react';

import { Outlet, Link, useRouteLoaderData,useLoaderData  } from "react-router-dom";
import './Root.scss';
import { basketStore } from '../Stores/BasketStore';
export default function Root(props) {
 
  console.log("useRouteLoaderData",useRouteLoaderData ("root"),useLoaderData(),props);

  return (
    <div className={"checkout-app"}> 
      <Outlet />
    </div>
  );
}